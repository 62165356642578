import * as React from "react";
import { graphql } from "gatsby";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Components
import {
  Heading,
  PageHeading,
  Paragraph,
  Subheading,
} from "@components/typography";
import { Box } from "@components/box";
import { GatsbyLink } from "@components/gatsby-link";
import { ProvinceLicenseContainer } from "@containers/province-license/province-license.style";

const ProvinceLicenseTemplate = (props: any) => {
  const { contentfulProvinceLicense }: any = props.data;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const imgAlt = node.data.target.title || "iCash image";
        const gatsbyImage = getImage(node.data.target.gatsbyImageData);

        if (gatsbyImage)
          return (
            <Box className="inline-block">
              <GatsbyImage id={node.data.target.contentful_id} image={gatsbyImage} alt={imgAlt} loading="lazy" />
            </Box>
          );
          return null;
      },
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <Box className="py-[2rem]">
          <PageHeading>{children}</PageHeading>
        </Box>
      ),
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <Box className="py-[1.5rem]">
          <Heading>{children}</Heading>
        </Box>
      ),
      [BLOCKS.HEADING_3]: (_node: any, children: any) => (
        <Box className="py-[1rem]">
          <Subheading>{children}</Subheading>
        </Box>
      ),
      [BLOCKS.HEADING_5]: (_node: any, children: any) => (
        <Box className="py-[1rem]">
          <Subheading>{children}</Subheading>
        </Box>
      ),
      [BLOCKS.HEADING_6]: (_node: any, children: any) => (
        <Box className="py-[0.75rem]">
          <p className="!text-[14px]">{children}</p>
        </Box>
      ),
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => (
        <Box className="py-[0.75rem]">
          <Paragraph className="text-interface-300 whitespace-pre-line">{children}</Paragraph>
        </Box>
      ),
      [BLOCKS.UL_LIST]: (_node: any, children: any) => <ul>{children}</ul>,
      [BLOCKS.HR]: (_node: any) => <hr/>,
      [BLOCKS.TABLE]: (_node: any, children: any) => <table>{children}</table>,
      [BLOCKS.TABLE_ROW]: (_node: any, children: any) => <tr>{children}</tr>,
      [BLOCKS.TABLE_HEADER_CELL]: (_node: any, children: any) => <th>{children}</th>,
      [BLOCKS.TABLE_CELL]: (_node: any, children: any) => <td className="px-[15px]">{children}</td>,
      [INLINES.HYPERLINK]: (_node: any, children: any) => (
        <GatsbyLink
          target="_blank"
          to={_node.data.uri}
          rel={
            _node.data.uri.startsWith("https://icash.ca")
              ? "noopener"
              : "noopener nofollow"
          }
        >
          {children}
        </GatsbyLink>
      ),
    },
    renderMark: {
      [MARKS.BOLD]: (children: any) => (<span className="font-600">{children}</span>),
    },
  };

  return (
    <ProvinceLicenseContainer province={contentfulProvinceLicense.slug} className="max-w-[950px] mx-auto p-[15px]">
      <Box className="description-block">
        {renderRichText(contentfulProvinceLicense.description, options)}
      </Box>
      <Box display="flex" className="license-block flex-col gap-[15px]">
        <Box display="flex" className="rate-block flex-col">
          {renderRichText(contentfulProvinceLicense.rateDescription, options)}
        </Box>
        <GatsbyLink className="img-license" inheritStyle to={contentfulProvinceLicense.licenseImage.file.url} title={contentfulProvinceLicense.provinceName + " license"}>
          <GatsbyImage
            image={contentfulProvinceLicense.licenseImage.gatsbyImageData}
            alt={contentfulProvinceLicense.licenseImage.title}
            loading="lazy"
          />
        </GatsbyLink>
        {contentfulProvinceLicense.disclaimerText != null ? (
          <Box className="disclaimer-block">
            {renderRichText(contentfulProvinceLicense.disclaimerText, options)}
          </Box>
        ) : null }
      </Box>
    </ProvinceLicenseContainer>
  );
};

export default ProvinceLicenseTemplate;

export const pageQuery = graphql`
  query ProvinceLicenseList($slug: String!) {
    contentfulProvinceLicense(slug: { eq: $slug }) {
      slug
      provinceName
      description {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
          }
          title
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], height: 48, quality: 70)
        }
      }
      licenseImage {
        title
        file {
          fileName
          url
        }
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 950)
      }
      rateDescription {
        raw
      }
      disclaimerText {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
          }
          title
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 950, quality: 70)
        }
      }
    }
  }
`;
