import styled from "styled-components";
import tw from "tailwind-styled-components";

import { ProvinceLicenseContainerProps } from "./province-license.type";

const StyledProvinceLicense = styled.div<ProvinceLicenseContainerProps>`
  div > p {
    color: #000;
    font-size: 16px;
  }
  .description-block {
    hr {
      margin-top: 20px;
    }
  }
  ${(p) => {
    switch (p.province) {
      case "ontario-license":
        return `
          .rate-block {
            hr + div {
              padding-top: 0;
            }
            div:nth-child(3) {
              padding-bottom: 0;
            }
          }
          // Sidebar style
          .sidebar.license-block {
            flex-direction: row-reverse;
            > div {
              width: 50%;
              @media only screen and (max-width: 768px) {
                width: 100%;
              }
            }
          }
        `;
      case "manitoba-license":
        return `
            .license-block {
              table {
                border: 8px solid purple;
                tr > th > div > p {
                  font-size: 1.5rem;
                  font-weight: 500;
                  line-height: 1.2;
                  padding-top: 10px;
                  padding-left: 15px;
                  padding-right: 15px;
                  text-align: left;
                }
              }
            }
            .disclaimer-block {
              order: 2;
              div {
                padding-top: 0;
              }
            }
            .img-license {
              order: 3;
            }
            // Sidebar style
            .sidebar.license-block {
              flex-direction: column;
            }
          `;
      case "nova-scotia-license":
        return `
            table {
              border: 8px solid #519DBD;
              tr > th > div {
                width: 142%;
                > p {
                  font-size: 1.5rem;
                  font-weight: 500;
                  line-height: 1.2;
                  padding-top: 10px;
                  padding-left: 15px;
                  padding-right: 15px;
                  text-align: left;
                }
              }
              tr > td > div {
                padding: 0;
              }
              tr > td:last-child > div > p {
                text-align: right;
              }
              tr:nth-child(2) {
                > td > div {
                  width: 142%;
                  margin-bottom: 15px;
                }
              }
              tr:nth-child(4) {
                > td {
                  &:first-child {
                    padding-right: 0;
                  }
                  &:last-child {
                    padding-left: 0;
                  }
                  > div > p {
                    border-bottom: 1px solid #ccc;
                    padding-bottom: 20px;
                  }
                }
                + tr > td > div {
                  padding-top: 20px;
                }
              }
              tr:last-child > td > div {
                margin-bottom: 20px;
              }
            }
            .disclaimer-block {
              img {
                width: 615px;
                height: auto;
              }
            }
            // Sidebar style
            .sidebar.license-block {
              flex-wrap: wrap;
              > div {
                flex: 0 48%;
                @media only screen and (max-width: 768px) {
                  flex: 0 100%;
                  width: 100%;
                }
              }
              > .disclaimer-block {
                flex: 100%;
                img {
                  width: 100%;
                  margin: 0 auto;
                  display: block;
                }
              }
            }
          `;
      case "british-columbia-license":
        return `
            .description-block {
              > div:nth-child(2) {
                margin-bottom: 20px;
              }
            }
            .license-block {
              flex-direction: column-reverse !important;
              table {
                border: 8px solid purple;
              }
              table {
                tr:last-child td > div > p {
                  font-size: 14px;
                }
              }
            }
            // Sidebar style
            .sidebar.license-block {
              flex-direction: row-reverse !important;
              @media only screen and (max-width: 768px) {
                flex-direction: column-reverse !important;
              }
            }
          `;
      case "new-brunswick-license":
        return `
            .license-block {
              flex-direction: column !important;
              > div:nth-child(1) {
                order: 2;
              }
              > div:nth-child(2) {
                order: 1;
              }
              > div:nth-child(3) {
                order: 3;
              }
              .rate-block table {
                border: 8px solid #E26C09;
                margin-top: 15px;
  
                tr > td {
                  text-align: center;
                  p {
                    line-height: 1;
                    color: #000;
                  }
                  div:first-child p {
                    font-size: 52pt;
                  }
                  div:nth-of-type(2) p {
                    font-size: 90pt;
                  }
                  div:nth-of-type(3) p {
                    font-size: 30pt;
                    font-weight: 500;
                  }
                  div:nth-of-type(4) p, div:last-child p {
                    font-size: 26pt;
                  }
                }
              }
            }
            .disclaimer-block {
              table {
                border: 1px solid #5078b3;
                tr > th {
                  background: #5078B3;
                  text-align: left;
                  div {
                    &:first-child {
                      padding-bottom: 0;
                    }
                    &:last-child {
                      padding-top: 0;
                    }
                    > p {
                      font-size: 22px;
                      color: #ffffff;
                      padding: 0 15px;
                      span {
                        font-weigth: 600;
                      }
                    }
                  }
                  &:not(:first-child) div:last-child > p {
                    font-size: 14px;
                  }
                }
                tr > td {
                  text-align: left;
                  div {
                    padding: 5px 0;
                    > p {
                      font-size: 16px;
                      padding: 0 15px;
                      span {
                        font-size: 16px;
                      }
                    }
                  }
                }
                tr:nth-child(odd) {
                  background-color: #f5f8fd;
                }
              }
              div:last-child {
                text-align: center;
                code {
                  font-family: 'Poppins';
                }
              }
            }
          `;
        case "prince-edward-island-license":
          return `
            .license-block {
              flex-direction: col-reverse;
              .rate-block {
                hr {
                  border-color: #000;
                }
                hr + div {
                  padding-top: 0;
                }
                div:nth-child(3) {
                  padding-bottom: 0;
                }
              }
            }
            // Sidebar style
            .sidebar.license-block {
              flex-direction: row-reverse;
              > div {
                width: 50%;
                @media only screen and (max-width: 768px) {
                  width: 100%;
                }
              }
            }
          `;
      default:
        return "";
    }
  }}
`;

export const ProvinceLicenseContainer = tw(StyledProvinceLicense)`
`;
